import React, { useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled, { ThemeProvider } from "styled-components"
import { Grid, Row, Col } from "react-styled-flexboxgrid"
import { chunk } from "lodash"

import { Layout, Section } from "../components/layout"
import Seo from "../components/Seo"
import siteTheme from "../constants/siteTheme"
import heroBackgroundImage from "../images/compressed/window-cleaning-edmonton-hero.jpg"
import PrimaryHeading from "../components/typography/PrimaryHeading"
import Hero from "../components/Hero"
import facebookReviews from "../constants/facebookReviews"
import FacebookReview from "../components/FacebookReview"
import ExternalLink from "../components/ExternalLink"
import { BodyText } from "../components/typography"

const Reviews = styled.div`
  margin-top: 0.5rem;
`

const TestimonialsPage = () => {
  useEffect(() => {
    window.fbAsyncInit = function() {
      window.FB.init({
        appId: "950236865343687",
        status: true,
        xfbml: true,
        version: "v5.0",
      })
    }
    const script = document.createElement("script")
    script.async = true
    script.defer = true
    script.crossOrigin = "anonymous"
    script.src = "https://connect.facebook.net/en_US/sdk.js"
    document.body.appendChild(script)
    return () => {
      document.body.removeChild(script)
      delete window.FB
    }
  }, [])
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          facebookUrl
        }
      }
    }
  `)
  return (
    <ThemeProvider theme={siteTheme}>
      <Layout>
        <div id="fb-root"></div>
        <Seo
          title="Dr. Squeegee Window Cleaning: Testimonials"
          description="Our business thrives on customer feedback. See what your neighbors around Edmonton are saying about Dr. Squeegee Window Cleaning!"
        />
        <Hero imageUrl={heroBackgroundImage}>
          <Grid>
            <PrimaryHeading className="centered">Testimonials</PrimaryHeading>
          </Grid>
        </Hero>
        <Grid>
          <Section>
            <Row>
              <Col xs={12} md={8} mdOffset={2}>
                <BodyText>
                  We guarantee your satisfaction&mdash;just see what some of our
                  customers have to say! Our business thrives on customer
                  feedback. Let us how we did on our{" "}
                  <ExternalLink href={data.site.siteMetadata.facebookUrl}>
                    Facebook page
                  </ExternalLink>
                  .
                </BodyText>
              </Col>
              <Col xs={12} md={10} mdOffset={1}>
                <Reviews>
                  {chunk(facebookReviews, 3).map((chunk, i) => (
                    <Row key={`reviewRow${i}`}>
                      {chunk.map(review => (
                        <Col xs={12} sm={4} key={review.href}>
                          <FacebookReview {...review} />
                        </Col>
                      ))}
                    </Row>
                  ))}
                </Reviews>
              </Col>
            </Row>
          </Section>
        </Grid>
      </Layout>
    </ThemeProvider>
  )
}

export default TestimonialsPage
